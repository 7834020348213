import { z } from 'zod';


// #/components/schemas/yaspaBankingApiYaspaPayInRequest
export const YaspaBankingApiYaspaPayInRequestZod = z.object({
    customerIdentifier: z.string(),
    paymentGiro: z.string(),
    amount: z.string(),
    currency: z.string(),
    reference: z.string(),
    journeyType: z.string(),
    searchableText: z.string().optional(),
    supportedCountries: z.array(z.string()).optional(),
    language: z.string().optional(),
    successRedirectUrl: z.string().optional(),
    failureRedirectUrl: z.string().optional(),
    successBankRedirectUrl: z.string().optional(),
    failureBankRedirectUrl: z.string().optional(),
});
export type YaspaBankingApiYaspaPayInRequestType = z.TypeOf<typeof YaspaBankingApiYaspaPayInRequestZod>;


// #/components/schemas/yaspaBankingApiYaspaLinkResponse
export const YaspaBankingApiYaspaLinkResponseZod = z.object({
    link: z.string(),
});
export type YaspaBankingApiYaspaLinkResponseType = z.TypeOf<typeof YaspaBankingApiYaspaLinkResponseZod>;


// #/components/schemas/yaspaBankingApiSuccessYaspaLinkResponse
export const YaspaBankingApiSuccessYaspaLinkResponseZod = z.object({
    data: YaspaBankingApiYaspaLinkResponseZod,
});
export type YaspaBankingApiSuccessYaspaLinkResponseType = z.TypeOf<typeof YaspaBankingApiSuccessYaspaLinkResponseZod>;


// #/components/schemas/yaspaBankingApiBadRequestError
export const YaspaBankingApiBadRequestErrorZod = z.object({
    code: z.string(),
    message: z.string(),
});
export type YaspaBankingApiBadRequestErrorType = z.TypeOf<typeof YaspaBankingApiBadRequestErrorZod>;


// #/components/schemas/yaspaBankingApiErrorBadRequestError
export const YaspaBankingApiErrorBadRequestErrorZod = z.object({
    errors: z.array(YaspaBankingApiBadRequestErrorZod).optional(),
});
export type YaspaBankingApiErrorBadRequestErrorType = z.TypeOf<typeof YaspaBankingApiErrorBadRequestErrorZod>;


// #/components/schemas/yaspaBankingApiUnauthorized
export const YaspaBankingApiUnauthorizedZod = z.object({
    code: z.string(),
    message: z.string(),
});
export type YaspaBankingApiUnauthorizedType = z.TypeOf<typeof YaspaBankingApiUnauthorizedZod>;


// #/components/schemas/yaspaBankingApiErrorUnauthorized
export const YaspaBankingApiErrorUnauthorizedZod = z.object({
    errors: z.array(YaspaBankingApiUnauthorizedZod).optional(),
});
export type YaspaBankingApiErrorUnauthorizedType = z.TypeOf<typeof YaspaBankingApiErrorUnauthorizedZod>;


// #/components/schemas/yaspaBankingApiInternalServerError
export const YaspaBankingApiInternalServerErrorZod = z.object({
    code: z.string(),
    message: z.string(),
});
export type YaspaBankingApiInternalServerErrorType = z.TypeOf<typeof YaspaBankingApiInternalServerErrorZod>;


// #/components/schemas/yaspaBankingApiErrorInternalServerError
export const YaspaBankingApiErrorInternalServerErrorZod = z.object({
    errors: z.array(YaspaBankingApiInternalServerErrorZod).optional(),
});
export type YaspaBankingApiErrorInternalServerErrorType = z.TypeOf<typeof YaspaBankingApiErrorInternalServerErrorZod>;


// #/components/schemas/yaspaBankingApiAccountReference
export const YaspaBankingApiAccountReferenceZod = z.object({
    id: z.number(),
    name: z.string(),
    type: z.string(),
});
export type YaspaBankingApiAccountReferenceType = z.TypeOf<typeof YaspaBankingApiAccountReferenceZod>;


// #/components/schemas/yaspaBankingApiPayOutRequest
export const YaspaBankingApiPayOutRequestZod = z.object({
    customerIdentifier: z.string(),
    amount: z.string(),
    currency: z.string(),
    paymentGiro: z.string(),
    reference: z.string(),
    description: z.string(),
    supportedCountries: z.array(z.string()).optional(),
    language: z.string().optional(),
    successRedirectUrl: z.string().optional(),
    failureRedirectUrl: z.string().optional(),
    successBankRedirectUrl: z.string().optional(),
    failureBankRedirectUrl: z.string().optional(),
    assignee: YaspaBankingApiAccountReferenceZod.optional(),
    channel: z.string().optional(),
});
export type YaspaBankingApiPayOutRequestType = z.TypeOf<typeof YaspaBankingApiPayOutRequestZod>;

