import { observable, action, computed, makeObservable } from 'mobx';
import { amountViewToValue } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/amountViewToValue';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import {
    validateAmountRequire,
    validateMaxDeposit,
    validateMinAmountNew,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { Amount } from 'src_common/common/amount/Amount';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { AmountPrecision } from 'src_common/common/amount/AmountPrecision';
import { TrpcClient } from 'src/appState/TrpcClient';
import {
    PaysafeBillingInfoSuccess,
    paysafeBillingInfoFieldsValidation,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/paysafeBillingInfoFieldsValidation';
import {
    PaymentIframeData,
    PaysafeError,
    PaysafeExpired,
    PaysafeInstance,
    PaysafeResult,
    PaysafeStage,
} from './paysafeFrame/PaysafeFrameOptions';
import { PaysafePaymentProviderType } from 'src/domains/layouts/config/features/types';
import { DepositViewType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { CreatePaymentPaysafeType } from 'src_server/trpc/types/createPaymentPaysafe';
import { createPaymentPaysafe } from 'src_server/trpc/types';
import {
    FinalStepType,
    TransactionCallbackType,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';
import { RemainingLimitState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit.state';
import { AccountState } from 'src/domains/players/shared/Types';
import { DateTime } from 'src_common/utils/time/time';
import { DepositYaspaPageType } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/Yaspa.state';
import { MessageIframeYaspaDataType } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaIframe';
import { Logger } from 'src/domains/common/Logger';
export interface IframeViewDataType {
    paymentIframeData: PaymentIframeData;
}
interface PaysafeFrameDataType {
    paymentIframeData: PaymentIframeData;
    paysafeDepositSteps: PaysafeDepositSteps;
}

export type DepositStepsType =
    | {
          readonly type: 'set-method';
      }
    | {
          readonly type: 'set-method-signup';
      }
    | {
          readonly type: 'iframe-view';
          paymentIframeData: PaymentIframeData;
          paysafeDepositSteps: PaysafeDepositSteps;
      }
    | {
          readonly type: 'iframe-view-yaspa';
          readonly url: string;
      }
    | {
          readonly type: 'final-view';
          transactionId: number | 'yaspaOpenBanking';
      }
    | {
          readonly type: 'failure-view';
          readonly failureType: DepositViewType;
          readonly transactionCallback?: TransactionCallbackType;
      };

export class PaysafeDepositSteps {
    @observable.ref public step: DepositStepsType;

    public constructor(private readonly isSignup: boolean) {
        makeObservable(this);
        this.step = {
            type: this.isSignup ? 'set-method-signup' : 'set-method',
        };
    }

    @action public redirectToSetMethod = (): void => {
        this.step = {
            type: this.isSignup ? 'set-method-signup' : 'set-method',
        };
    };

    @action public redirectToIframeNewCard = ({
        paymentIframeData,
        paysafeDepositSteps,
    }: PaysafeFrameDataType): void => {
        this.step = {
            type: 'iframe-view',
            paymentIframeData,
            paysafeDepositSteps,
        };
    };

    @action public redirectToIframeYaspa = ({ url }: { url: string }): void => {
        this.step = {
            type: 'iframe-view-yaspa',
            url,
        };
    };

    @action public redirectFromYaspaIframe = (data?: MessageIframeYaspaDataType): void => {
        if (data?.eventType === 'onCloseHiddenIframeFail') {
            this.step = {
                type: 'failure-view',
                failureType: 'serverIssue',
            };
        } else if (data?.eventType === 'onCloseHiddenIframeSuccess') {
            this.step = {
                type: 'final-view',
                transactionId: 'yaspaOpenBanking',
            };
        }
    };

    @action public redirectToFinalView = (transactionId: number): void => {
        this.step = {
            type: 'final-view',
            transactionId,
        };
    };

    @action public redirectToFailureView = (
        failureType: DepositViewType,
        transactionCallback?: TransactionCallbackType
    ): void => {
        this.step = {
            type: 'failure-view',
            failureType,
            transactionCallback,
        };
    };
}

export class PaysafeTabState {
    public stepsState: PaysafeDepositSteps;

    @observable public refDepositInput: HTMLInputElement | null;
    @observable public errorMessage: Array<string> = [];
    @observable public showMoreRingFenceFlag: boolean = false;
    @observable public transactionId: number | undefined = undefined;

    public readonly depositAmount: FormInputState<string, Amount>;

    public constructor(
        private readonly usersState: UsersState,
        public readonly googleTagManager: GoogleTagManagerState,
        private readonly isSignup: boolean,
        public readonly languagesState: LanguagesState,
        public readonly minDepositAmount: Amount,
        public readonly amountPrecision: AmountPrecision,
        private readonly trpcClient: TrpcClient,
        private readonly operatorFullName: string,
        private readonly paysafePaymentProviderConfig: PaysafePaymentProviderType,
        private readonly accountHelperMail: string,
        private readonly account: AccountState,
        public readonly remainingLimitState: RemainingLimitState,
        public readonly websiteBaseUrl: string
    ) {
        makeObservable(this);
        this.stepsState = new PaysafeDepositSteps(this.isSignup);

        this.refDepositInput = null;
        this.depositAmount = FormInputState.new('')
            .map(validateAmountRequire)
            .map(
                validateMinAmountNew(
                    this.minDepositAmount,
                    this.languagesState.getTranslation(
                        'errors.min-deposit',
                        'Minimum deposit amount is {currencySymbol}{minValue}',
                        { currencySymbol: this.usersState.moneySymbol, minValue: this.minDepositAmount.value }
                    )
                )
            )
            .map(
                validateMaxDeposit(
                    () => this.remainingLimitState.getRemainingLimit ?? null,
                    () => this.usersState.currency,
                    this.languagesState,
                    () => this.account.account?.basicDataReady?.depositRestriction,
                    this.accountHelperMail
                )
            );
    }

    @computed public get depositYaspaPage(): DepositYaspaPageType {
        return {
            isSignup: this.isSignup,
            getAmountInput: () => this.depositAmount,
            handleRedirectToYaspaFrame: (url) => this.stepsState.redirectToIframeYaspa({ url }),
        };
    }

    @computed public get showBalance(): string | undefined {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
    }

    public hideDepositSuccess = (): void => {
        this.onSetInputToEmpty();
        this.stepsState.redirectToSetMethod();
    };

    /* amount Input start*/
    public onChange = (): void => {
        const formatValue = amountViewToValue(this.depositAmount.value);
        this.depositAmount.setValue(formatValue);
        this.clearErrorMessage();
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.depositAmount.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.depositAmount.setValue('');
        }
        return this.depositAmount.setValue(amount.toFixed(2));
    };

    public handleAdditionsChange = (amount: Amount): void => {
        this.depositAmount.setValue(amount.value);
        this.depositAmount.setAsVisited();
        this.clearErrorMessage();
    };

    public onSetInputToEmpty = (): void => {
        this.depositAmount.reset();
    };

    @computed public get hasInputValue(): boolean {
        return this.depositAmount.value !== '';
    }
    /* amount Input end*/

    /* ringFencedFundsFlag start */
    public toggleShowMore = (): void => {
        this.showMoreRingFenceFlag = !this.showMoreRingFenceFlag;
    };

    @computed public get hasRingFencedFunds(): boolean {
        return this.usersState.basicData.valueReady?.ringFencedFunds ?? false;
    }

    @action public updateRingFencedFunds = async (): Promise<void> => {
        const response = await this.trpcClient.client.accounts.changeRingFencedFunds.mutate({ ringFencedFunds: true });

        if (response.responseStatus === 'success') {
            this.usersState.basicData.refresh();
        }
    };
    /* ringFencedFundsFlag end*/

    private clearErrorMessage = (): void => {
        this.errorMessage = [];
    };

    private mapIframeData = (
        singleUseToken: string,
        billingInfo: PaysafeBillingInfoSuccess['data'],
        amount: number
    ): PaymentIframeData => {
        return {
            customerId: billingInfo.merchantCustomerId,
            data: {
                currency: billingInfo.currency,
                amount: amount,
                singleUseCustomerToken: singleUseToken,
                customer: billingInfo.customer,
                billingAddress: billingInfo.billingAddress,
                merchantRefNum: `${billingInfo.merchantCustomerId}-${singleUseToken}`,
                simulator: 'EXTERNAL',
                environment: this.paysafePaymentProviderConfig.environment,
                buttonColor: '#66cc99',
                imageUrl: 'https://hosted.paysafe.com/checkout/resource/demo-store/images/logo.png',
                companyName: this.operatorFullName,
                holderName: billingInfo.billingAddress.nickName,
                threeDs: {
                    merchantUrl: this.websiteBaseUrl,
                    deviceChannel: 'BROWSER',
                    messageCategory: 'PAYMENT',
                    transactionIntent: 'GOODS_OR_SERVICE_PURCHASE',
                    authenticationPurpose: 'PAYMENT_TRANSACTION',
                },
                displayPaymentMethods: ['card'],
                paymentMethodDetails: {
                    card: {
                        accountId:
                            billingInfo.currency === 'GBP'
                                ? this.paysafePaymentProviderConfig.cardAccounts.GBP
                                : this.paysafePaymentProviderConfig.cardAccounts.EUR,
                    },
                },
            },
        };
    };

    public paysafeResultCallback =
        (paymentIframeData: PaymentIframeData) =>
        async (instance: PaysafeInstance | null, error: unknown, result: unknown): Promise<void> => {
            try {
                const date = new Date().toISOString();
                const parsedError = PaysafeError.safeParse(error);
                if (parsedError.success) {
                    console.error('paysafe-iframe-error', parsedError.data);
                    instance?.showFailureScreen(parsedError.data.displayMessage);
                    Logger.captureError(`Paysafe -> SDK error -> ${JSON.stringify(parsedError.data)}`, 'Players');

                    await this.trpcClient.client.paysafeRouter.failedPayment.mutate({
                        amount: this.amountPrecision.newFromOld(paymentIframeData.data.amount).value,
                        currencyCode: paymentIframeData.data.currency,
                        merchantRefNum: paymentIframeData.data.merchantRefNum,
                        error: parsedError.data,
                    });

                    this.stepsState.redirectToFailureView('failWithReceipt', {
                        transactionId: undefined,
                        data: {
                            currency: paymentIframeData.data.currency,
                            amount: paymentIframeData.data.amount,
                            createdAt: date,
                            status: 'failed',
                            orderId: parsedError.data.correlationId,
                        },
                    });
                    return;
                }

                const parsedResult = PaysafeResult.safeParse(result);
                if (parsedResult.success) {
                    const handlePaymentResponse = await this.handlePayment({
                        amount: this.amountPrecision.newFromOld(paymentIframeData.data.amount).value,
                        currencyCode: paymentIframeData.data.currency,
                        description: 'Top-up',
                        keywords: ['Top-up'],
                        paymentHandleToken: parsedResult.data.paymentHandleToken,
                    });

                    if (handlePaymentResponse.responseStatus === 'error') {
                        const error = handlePaymentResponse.data.errors[0];
                        instance?.showFailureScreen(
                            this.createErrorMessage(
                                error?.code ?? undefined,
                                error?.message ?? undefined,
                                error?.fieldErrors ?? []
                            )
                        );
                        this.stepsState.redirectToFailureView('failWithReceipt', {
                            transactionId: undefined,
                            data: {
                                currency: paymentIframeData.data.currency,
                                amount: paymentIframeData.data.amount,
                                createdAt: date,
                                status: 'failed',
                            },
                        });
                        return;
                    }

                    instance?.showSuccessScreen('Remember to always gamble responsibly');
                    this.stepsState.redirectToFinalView(handlePaymentResponse.response.data.transactionId);
                    return;
                }

                instance?.showFailureScreen(this.createErrorMessage());
                Logger.captureError(`Paysafe -> Unknown error`, 'Players');
                this.stepsState.redirectToFailureView('serverIssue');
                return;
            } catch (e) {
                console.error('paysafe-iframe-catch-error', e);
                Logger.captureError(`Paysafe -> Iframe catch error -> ${JSON.stringify(e)}`, 'Players');
                this.stepsState.redirectToFailureView('serverIssue');
                instance?.close();
            }
        };

    public paysafeStateCallback = (stage: unknown, expired: unknown): void => {
        const parsedExpired = PaysafeExpired.safeParse(expired);
        if (parsedExpired.success && parsedExpired.data === true) {
            this.stepsState.redirectToFailureView('contactGeneralIssue');
            return;
        }

        const parsedStage = PaysafeStage.safeParse(stage);

        if (parsedStage.success) {
            if (parsedStage.data === 'PAYMENT_HANDLE_NOT_CREATED') {
                this.stepsState.redirectToFailureView('contactGeneralIssue');
                return;
            }
        }

        return;
    };

    @action public handlePayment = async (
        requestData: CreatePaymentPaysafeType
    ): Promise<createPaymentPaysafe.TresponseType> => {
        return await this.trpcClient.client.paysafeRouter.createPayment.mutate(requestData);
    };

    @action public submitDepositForm = async (): Promise<void> => {
        this.depositAmount.setAsVisited();
        this.clearErrorMessage();

        if (this.depositAmount.result.value.type === 'error') {
            console.error('submitDepositForm - Input error');
            return;
        }

        const oldFormatAmount = this.amountPrecision.valueOldFormat(this.depositAmount.result.value.data);
        const basicData = this.usersState.basicData.valueReady ?? null;

        const billingInfo = paysafeBillingInfoFieldsValidation(basicData);
        if (billingInfo?.type !== 'ok') {
            console.error('submitDepositForm - No basic user data');
            return;
        }

        try {
            if (this.hasRingFencedFunds === false) {
                await this.updateRingFencedFunds();
            }

            if (this.isSignup) {
                this.googleTagManager.depositedMoney(oldFormatAmount, true);
                this.googleTagManager.gtmSignUpStepFour(oldFormatAmount);
            } else {
                this.googleTagManager.depositedMoney(oldFormatAmount, false);
            }

            const verifyUserResponse = await this.trpcClient.client.paysafeRouter.verifyUser.mutate({
                merchantCustomerId: billingInfo.data.merchantCustomerId.toString(),
                ...billingInfo.data.customer,
            });

            if (verifyUserResponse.responseStatus === 'error') {
                throw new Error();
            }
            const date = new Date().toUTCString();
            const singleUseTokenResponse =
                await this.trpcClient.client.paysafeRouter.createSingleUseCustomerToken.mutate({
                    externalCustomerId: verifyUserResponse.response.data.externalId,
                    body: {
                        merchantRefNum: `${billingInfo.data.merchantCustomerId.toString()}-${date}`,
                        paymentMethod: ['card'],
                    },
                });

            if (singleUseTokenResponse.responseStatus === 'error') {
                throw new Error();
            }

            this.stepsState.redirectToIframeNewCard({
                paymentIframeData: this.mapIframeData(
                    singleUseTokenResponse.response.data.singleUseCustomerToken,
                    billingInfo.data,
                    oldFormatAmount
                ),
                paysafeDepositSteps: this.stepsState,
            });
        } catch (e) {
            this.stepsState.redirectToFailureView('serverIssue');
            throw e;
        }
    };

    public createErrorMessage = (errorType?: string, message?: string, requiredFields?: Array<string>): string => {
        switch (errorType) {
            case 'invalid-data':
                return this.languagesState.getTranslation('errors.invalid.general', 'Some fields are invalid.');
            case 'minimum':
                return this.languagesState.getTranslation(
                    'account.top-up.errors.deposit-minimum-amount',
                    'Minimum deposit amount is £1'
                );
            case 'deposits-not-allowed':
                return this.languagesState.getTranslation('errors.unauthorized-client', 'Your account is suspended.');
            case 'limit-deposit-restriction':
            case 'limit-reached':
                return this.languagesState.getTranslation(
                    'account.top-up.errors.limit-deposit-restriction',
                    "You're approaching your current net deposit limit! To adjust your limit, whether to increase or decrease it, please reach out to us at {email}. We're here to help!",
                    {
                        email: this.accountHelperMail,
                    }
                );
            case 'backoffice-deposit-restriction':
                return this.languagesState.getTranslation(
                    'account.top-up.errors.backoffice-deposit-restriction',
                    'Your deposits have been restricted. In case of any questions, please get in touch with us at {email}.',
                    {
                        email: this.accountHelperMail,
                    }
                );
            case 'failed-deposit-restriction':
                if (message === undefined) {
                    return '';
                }
                return this.languagesState.getTranslation(
                    'account.top-up.errors.failed-deposit-restriction',
                    "We've noticed that there were unsuccessful deposit attempts on your account due to insufficient funds. We understand that managing finances can be challenging, and we want to ensure that your gambling experience remains safe and enjoyable. To give you some time to consider your gambling spend and review your finances we have placed deposit break on your account until {date}. If you would like to discuss any of our [responsibleGamblingTools], please reach out to us at {email}. We're here to help!",
                    {
                        email: this.accountHelperMail,
                        date: DateTime.from(message)?.format('DD-MM-YYYY HH:mm') ?? '',
                    }
                );
            case 'kyc-deposit-restriction':
                return this.languagesState.getTranslation(
                    'account.top-up.errors.kyc-deposit-restriction',
                    'Your deposits are temporarily restricted until we receive your KYC information. Please check your email for further instructions and provide the necessary details. Once verified, the restriction will be lifted. For any questions, contact us at {email}.',
                    {
                        email: this.accountHelperMail,
                    }
                );
            case 'declined':
                return this.languagesState.getTranslation(
                    'account.top-up.errors.declined',
                    'Your deposit has been declined, please contact your bank for further information'
                );
            case 'missing-fields':
                if (requiredFields === undefined) {
                    return '';
                }

                return `Please add customer ${requiredFields.join(', ')} to customer 'Personal account' details.`;
            default:
                if (errorType === undefined) {
                    return 'Unknown error happened';
                }

                if (message !== undefined) {
                    return `${errorType}: ${message}`;
                }

                return `Unknown error happened - ${errorType}`;
        }
    };

    /* nextButton start (in accounts Tab) */
    @computed public get isButtonDisabled(): boolean {
        if (this.depositAmount.result.value.type === 'error') {
            return true;
        }
        return false;
    }
    /* nextButton end */

    /* used in signUp start */

    public setDepositInputRef = (node: HTMLInputElement | null): void => {
        this.refDepositInput = node;
    };
    /* used in signUp end */

    public transactionCallback = async (transactionId: number): Promise<FinalStepType | null> => {
        const request = await this.trpcClient.client.paysafeRouter.checkoutCallbackPaysafe.query({
            transactionId: transactionId,
        });

        if (request.responseStatus === 'error') {
            return {
                type: 'failure-view',
                failType: 'serverIssue',
            };
        }
        const { status, createdAt, currency, merchantRefNum } = request.response;

        if (status === 'failed' || status === 'rejected') {
            return {
                type: 'failure-view',
                failType: 'failWithReceipt',
                data: {
                    data: {
                        orderId: merchantRefNum ?? undefined,
                        createdAt: createdAt,
                        currency: currency,
                        status,
                    },
                    transactionId: transactionId,
                },
            };
        }

        if (status === 'paid') {
            await this.remainingLimitState.depositLimitsDataResource.refresh();
            return {
                type: 'success-view',
            };
        }

        return null;
    };
}
