import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { observable, action, computed, makeObservable } from 'mobx';
import { AccountState } from 'src/domains/players/state/accountState/AccountState';
import { MessageDataType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/Iframe';
import { AccountModel } from 'src/domains/players/state/accountState/AccountModel';
import { amountViewToValue } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/amountViewToValue';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { UsersState } from 'src/domains/players/state/UsersState';
import { RealexDepositSteps } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/realexProvider/RealexJourney';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import {
    validateAmountRequire,
    validateMaxDeposit,
    validateMinAmountNew,
} from 'src/domains/players/webview/components/ValidatorsNew';
import { Amount } from 'src_common/common/amount/Amount';
import {
    BillingInfoSuccess,
    billingInfoFieldsValidationDeposit,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/billingInfoFiledsValidation/realexBillingInfoFieldsValidation';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { FinalStepType } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';
import { DateTime } from 'src_common/utils/time/time';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { RemainingLimitState } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit.state';
import { DepositYaspaPageType } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/Yaspa.state';

type ErrorType =
    | 'invalid-data'
    | 'minimum'
    | 'limit-reached'
    | 'deposits-not-allowed'
    | 'declined'
    | 'missing-fields'
    | 'failed-deposit-restriction'
    | 'limit-deposit-restriction'
    | 'kyc-deposit-restriction'
    | 'backoffice-deposit-restriction'
    | 'unknown';

export interface RealexErrorType {
    type: ErrorType;
    message: string;
}

export class RealexTabState {
    public stepsState: RealexDepositSteps;

    @observable public refDepositInput: HTMLInputElement | null;
    @observable public errors: RealexErrorType[] = [];
    @observable public showMoreRingFenceFlag: boolean = false;
    @observable public transactionId: number | undefined = undefined;

    private addedDepositTag = false;

    public readonly depositAmount: FormInputState<string, Amount>;

    public constructor(
        private readonly accountHelperMail: string,
        private readonly common: Common,
        private readonly account: AccountState,
        private readonly usersState: UsersState,
        public readonly googleTagManager: GoogleTagManagerState,
        public readonly isSignup: boolean,
        public readonly languagesState: LanguagesState,
        public readonly minDepositAmount: Amount,
        public readonly remainingLimitState: RemainingLimitState
    ) {
        makeObservable(this);
        this.stepsState = new RealexDepositSteps(this.isSignup);

        this.refDepositInput = null;
        this.depositAmount = FormInputState.new('')
            .map(validateAmountRequire)
            .map(
                validateMinAmountNew(
                    this.minDepositAmount,
                    this.languagesState.getTranslation(
                        'errors.min-deposit',
                        'Minimum deposit amount is {currencySymbol}{minValue}',
                        {
                            currencySymbol: BasicDataModel.get(this.common).moneySymbol,
                            minValue: this.minDepositAmount.value,
                        }
                    )
                )
            )
            .map(
                validateMaxDeposit(
                    () => this.remainingLimitState.getRemainingLimit ?? null,
                    () => this.usersState.currency,
                    this.languagesState,
                    () => this.account.account?.basicDataReady?.depositRestriction,
                    this.accountHelperMail
                )
            );

        this.areMissingFieldsForBillingInfoRealex();
    }

    public areMissingFieldsForBillingInfoRealex = (): boolean => {
        const basicData = BasicDataModel.get(this.common).basicDataReady ?? null;
        const billingInfo = billingInfoFieldsValidationDeposit(basicData);

        if (billingInfo?.type === 'error') {
            this.createErrorMessage('missing-fields', undefined, billingInfo.fieldsRequired);

            return true;
        }

        return false;
    };

    @computed public get depositYaspaPage(): DepositYaspaPageType {
        return {
            isSignup: this.isSignup,
            getAmountInput: () => this.depositAmount,
            handleRedirectToYaspaFrame: (url) => this.stepsState.redirectToIframeYaspa({ url }),
        };
    }

    @computed public get showBalance(): string | undefined {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
    }

    /* amount Input start*/
    public onChange = (): void => {
        const formatValue = amountViewToValue(this.depositAmount.value);
        this.depositAmount.setValue(formatValue);
        this.clearErrorMessage();
    };

    public setAmount = (): void => {
        const amount = parseFloat(this.depositAmount.value);
        if (isNaN(amount) === true || amount === 0) {
            return this.depositAmount.setValue('');
        }
        return this.depositAmount.setValue(amount.toFixed(2));
    };

    public handleAdditionsChange = (amount: Amount): void => {
        this.depositAmount.setValue(amount.value);
        this.depositAmount.setAsVisited();
        this.clearErrorMessage();
    };

    public onSetInputToEmpty = (): void => {
        this.depositAmount.reset();
    };

    @computed public get hasInputValue(): boolean {
        return this.depositAmount.value !== '';
    }
    /* amount Input end*/

    /* ringFencedFundsFlag start */
    public toggleShowMore = (): void => {
        this.showMoreRingFenceFlag = !this.showMoreRingFenceFlag;
    };

    @computed public get hasRingFencedFunds(): boolean {
        return BasicDataModel.get(this.common).basicDataReady?.ringFencedFunds ?? false;
    }
    /* ringFencedFundsFlag end*/

    private clearErrorMessage = (): void => {
        this.errors = [];
    };

    @action public submitDepositForm = async (): Promise<void> => {
        this.depositAmount.setAsVisited();
        this.clearErrorMessage();
        const amount = new Amount(this.depositAmount.value);
        const oldFormatAmount = ConfigComponents.get(this.common).precision.valueOldFormat(amount);
        const account = this.account.account;

        if (account === null) {
            console.error('submitDepositForm - User is anonymous');
            return;
        }

        const billingInfo = billingInfoFieldsValidationDeposit(account.basicDataReady);
        if (billingInfo?.type !== 'ok') {
            console.error('submitDepositForm - No basic user data');
            return;
        }

        try {
            if (this.hasRingFencedFunds === false) {
                await this.account.account?.onChangeRingFencedFlag();
            }

            if (this.isSignup) {
                this.googleTagManager.depositedMoney(oldFormatAmount, true);
                this.googleTagManager.gtmSignUpStepFour(oldFormatAmount);
            } else {
                this.googleTagManager.depositedMoney(oldFormatAmount, false);
            }

            //for new cards and saved card by iframe
            await this.onSubmitCard(oldFormatAmount, billingInfo.data, account);
        } catch (e) {
            this.stepsState.redirectToFailureView('serverIssue');
            throw e;
        }
    };

    private onSubmitCard = async (
        amount: number,
        billingInfoData: BillingInfoSuccess['data'],
        account: AccountModel
    ): Promise<void> => {
        const response = await account.onSendTopUpAmount({
            requestBody: {
                amount,
                paymentMethod: undefined,
                billingInfo: billingInfoData,
                channel: 'default',
                status: 'initiated',
            },
        });

        if (response.status === '200') {
            const { bodyJson } = response;
            this.transactionId = bodyJson.id;

            const paymentForm = bodyJson.paymentForm ?? false;

            if (bodyJson.status === 'failed') {
                if (bodyJson.reason === 'invalid-data') {
                    this.createErrorMessage('invalid-data');
                } else {
                    this.stepsState.redirectToFinalView(this.transactionId);
                }
            } else if (paymentForm === false) {
                throw new Error('Payment form is null');
            } else {
                this.stepsState.redirectToIframeNewCard({
                    formActionUrl: paymentForm.formActionUrl,
                    formData: paymentForm.formData,
                    onMessage: this.onMessage,
                });
            }
        } else {
            const { errors } = response;

            const error = errors[0];

            if (error === undefined) {
                this.stepsState.redirectToFailureView('serverIssue');
                return;
            }

            if (error.code === 'minimum') {
                this.createErrorMessage('minimum');
            } else if (error.code === 'limit-reached') {
                this.createErrorMessage('limit-reached');
            } else if (error.code === 'deposits-not-allowed') {
                this.createErrorMessage('deposits-not-allowed');
            } else if (error.code === 'failed-deposit-restriction') {
                this.createErrorMessage('failed-deposit-restriction', error.debugDetails ?? undefined);
            } else if (error.code === 'limit-deposit-restriction') {
                this.createErrorMessage('limit-deposit-restriction');
            } else if (error.code === 'backoffice-deposit-restriction') {
                this.createErrorMessage('backoffice-deposit-restriction');
            } else if (error.code === 'kyc-deposit-restriction') {
                this.createErrorMessage('kyc-deposit-restriction');
            } else {
                this.stepsState.redirectToFinalView(undefined);
            }
        }
    };

    @action public onMessage = (data: MessageDataType): void => {
        if (data.type === 'success') {
            this.stepsState.redirectToFinalView(this.transactionId);

            if (this.addedDepositTag === false) {
                this.addedDepositTag = true;
                this.googleTagManager.addDepositTag(this.account.account);
            }
        } else {
            this.stepsState.redirectToFinalView(this.transactionId);
        }
    };

    public hideDepositSuccess = (): void => {
        this.onSetInputToEmpty();
        this.stepsState.redirectToSetMethod();
    };

    public createErrorMessage = (errorType: ErrorType, debugDetails?: string, requiredFields?: Array<string>): void => {
        switch (errorType) {
            case 'invalid-data':
                this.errors.push({
                    type: 'invalid-data',
                    message: this.languagesState.getTranslation('errors.invalid.general', 'Some fields are invalid.'),
                });
                break;
            case 'minimum':
                this.errors.push({
                    type: 'minimum',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.deposit-minimum-amount',
                        'Minimum deposit amount is £1'
                    ),
                });
                break;
            case 'limit-reached':
                this.errors.push({
                    type: 'limit-reached',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.deposit-limit-reached',
                        "Deposit can't exceed defined limit. You can review your limits [link]."
                    ),
                });
                break;
            case 'deposits-not-allowed':
                this.errors.push({
                    type: 'deposits-not-allowed',
                    message: this.languagesState.getTranslation(
                        'errors.unauthorized-client',
                        'Your account is suspended.'
                    ),
                });
                break;
            case 'limit-deposit-restriction':
                this.errors.push({
                    type: 'limit-deposit-restriction',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.limit-deposit-restriction',
                        "You're approaching your current net deposit limit! To adjust your limit, whether to increase or decrease it, please reach out to us at {email}. We're here to help!",
                        {
                            email: this.accountHelperMail,
                        }
                    ),
                });
                break;
            case 'backoffice-deposit-restriction':
                this.errors.push({
                    type: 'backoffice-deposit-restriction',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.backoffice-deposit-restriction',
                        'Your deposits have been restricted. In case of any questions, please get in touch with us at {email}.',
                        {
                            email: this.accountHelperMail,
                        }
                    ),
                });
                break;
            case 'failed-deposit-restriction':
                if (debugDetails === undefined) {
                    return;
                }
                this.errors.push({
                    type: 'failed-deposit-restriction',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.failed-deposit-restriction',
                        "We've noticed that there were unsuccessful deposit attempts on your account due to insufficient funds. We understand that managing finances can be challenging, and we want to ensure that your gambling experience remains safe and enjoyable. To give you some time to consider your gambling spend and review your finances we have placed deposit break on your account until {date}. If you would like to discuss any of our [responsibleGamblingTools], please reach out to us at {email}. We're here to help!",
                        {
                            email: this.accountHelperMail,
                            date: DateTime.from(debugDetails)?.format('DD-MM-YYYY HH:mm') ?? '',
                        }
                    ),
                });
                break;

            case 'kyc-deposit-restriction':
                this.errors.push({
                    type: 'failed-deposit-restriction',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.kyc-deposit-restriction',
                        'Your deposits are temporarily restricted until we receive your KYC information. Please check your email for further instructions and provide the necessary details. Once verified, the restriction will be lifted. For any questions, contact us at {email}.',
                        {
                            email: this.accountHelperMail,
                        }
                    ),
                });
                break;
            case 'declined':
                this.errors.push({
                    type: 'declined',
                    message: this.languagesState.getTranslation(
                        'account.top-up.errors.declined',
                        'Your deposit has been declined, please contact your bank for further information'
                    ),
                });
                break;
            case 'missing-fields':
                if (requiredFields === undefined) {
                    return;
                }

                this.errors.push({
                    type: 'missing-fields',
                    message: `Please add customer ${requiredFields.join(', ')} to customer 'Personal account' details.`,
                });
                break;
            default:
                this.errors.push({ type: 'unknown', message: `Unknown error happen - ${errorType}` });
        }
    };

    /* nextButton start (in accounts Tab) */
    @computed public get isButtonDisabled(): boolean {
        if (this.depositAmount.result.value.type === 'error') {
            return true;
        }
        return false;
    }
    /* nextButton end */

    /* used in signUp start */

    public setDepositInputRef = (node: HTMLInputElement | null): void => {
        this.refDepositInput = node;
    };
    /* used in signUp end */

    public transactionCallback = async (transactionId: number): Promise<FinalStepType | null> => {
        try {
            const request = await this.common.trpcClient.client.realex.transactionCallbackRealex.query({
                transactionId,
            });
            if (request.responseStatus === 'error') {
                return {
                    type: 'failure-view',
                    failType: 'serverIssue',
                };
            }

            if (request.response.status === 'failed' || request.response.status === 'rejected') {
                return {
                    type: 'failure-view',
                    failType: 'failWithReceipt',
                    data: {
                        data: request.response,
                        transactionId,
                    },
                };
            }

            if (request.response.status === 'paid') {
                await this.remainingLimitState.depositLimitsDataResource.refresh();
                return {
                    type: 'success-view',
                };
            }

            return null;
        } catch (e) {
            return {
                type: 'failure-view',
                failType: 'serverIssue',
            };
        }
    };
}
