import React from 'react';
import { computed, makeObservable } from 'mobx';
import { getWindowInnerWidth } from 'src_common/common/mobx-utils/Services/window';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { LanguageTokenType } from 'src/domains/layouts/state/languagesState/LanguagesUtils';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { AppCasinoState } from 'src/domains/casino/shared/Types';
import { CasinoBannersSidebar, SidebarGameInfo } from 'src/domains/casino/shared/Components';
import { DepositProcedureProvider } from 'src/domains/players/webview/components/WithdrawAndDeposit';
import { TraderChatIcon } from 'src/domains/players/webview/components/Account/accountDrawerHeader/AccountDrawerHeader.style';
import { LoginTab } from 'src/domains/players/webview/components/Account/loginTab/LoginTab';
import { ForgotPasswordTab } from 'src/domains/players/webview/components/Account/forgotPasswordTab/ForgotPasswordTab';
import { VerifyAccountTab } from 'src/domains/players/webview/components/Account/verifyAccountTab/VerifyAccountTab';
import { SummaryTab } from 'src/domains/players/webview/components/Account/summaryTab/SummaryTab';
import { PersonalDetailsTab } from 'src/domains/players/webview/components/Account/personalDetailsTab/PersonalDetailsTab';
import { TransactionHistoryTab } from 'src/domains/players/webview/components/Account/transactionHistoryTab/TransactionHistoryTab';
import { ResetPasswordProcedure } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/ResetPasswordProcedure';
import { ResetPasswordPrivacy } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordPrivacy';
import { ResetPasswordTerms } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordTerms';
import { ResetPasswordCookies } from 'src/domains/players/webview/components/Account/resetPasswordProcedure/resetPasswordForm/ResetPasswordCookies';
import { BetHistoryTab } from 'src/domains/players/webview/components/Account/betHistoryTab/BetHistoryTab';
import { LimitsTab } from 'src/domains/players/webview/components/Account/limitsTab/LimitsTab';
import { StaticPageTab } from 'src/domains/players/webview/components/Account/staticPage/StaticPage';
import { StaticResourceHeroWidget } from 'src/domains/players/webview/components/Account/staticPage/StaticResourceHeroWidget';
import { StaticResourceHomePageCarouselTerms } from 'src/domains/players/webview/components/Account/staticPage/StaticResourceHomePageCarouselTerms';
import { CustomerFundsProtection } from 'src/domains/players/webview/components/Account/customerFundsProtection/CustomerFundsProtection';
import { PromotionTermsAndConditions } from 'src/domains/players/webview/components/Account/promotionTermsAndConditons/PromotionTermsAndConditions';
import {
    PromotionTermsAndConditions as PromotionTermsAndConditionsSpecial,
    PromotionTermsAndConditionsHeader,
} from 'src/domains/players/webview/components/Account/promoSidebar/promotionTermsAndConditions/PromotionTermsAndConditions';
import { WithdrawTab } from 'src/domains/players/webview/components/Account/withdrawTab/WithdrawTab';
import { TraderChat } from 'src/domains/players/webview/components/Account/traderChat/TraderChat';
import { PreferencesTab } from 'src/domains/players/webview/components/Account/preferencesTab/PreferencesTab';
import { BettingTab } from 'src/domains/sportsbook/shared/Components';
import { StaticResourceHeadlineCarouselTerms } from 'src/domains/players/webview/components/Account';
import { LuckyKingLogin, LuckyKingResetPasswordContainer } from 'src/domains/players/webview/components/LuckyKingLogin';
import { StaticResourceHeadlineCarouselTermsWrapper } from 'src/domains/layouts/webview/others/luckyKing/RHSDrawer';
import { LuckyKingWalletTab } from 'src/domains/players/webview/components/LuckyKingWallet';
import { observer } from 'src/utils/mobx-react';
import { SummaryTabSecondary } from 'src/domains/players/webview/components/Account/summaryTab/SummaryTabSecondary';
import { RightHandSideViewType, TopUpDrawerType } from 'src/domains/layouts/state/router/newRouter/rhsRouteType';
import { TraderChatTitleHeader } from 'src/domains/players/webview/components/Account/accountDrawerHeader/AccountDrawerHeader';
import { SignUp } from 'src/domains/players/webview/components/SignUp/SignUp';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Common } from 'src/domains/common/Common';
import { Credits } from 'src/domains/players/webview/components/Credits';
import { DepositFinalView } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/DepositFinalView';
import { StarRouter } from 'src/domains/layouts/state/router/StarRouter';
import { WithdrawSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawSuccess/WithdrawSuccess';
import { WithdrawIssue } from 'src/domains/players/webview/components/WithdrawAndDeposit/withdrawProcedure/withdrawProcedureParts/withdrawIssue/WithdrawIssue';

const LuckyKingWalletTabWrapper = observer('LuckyKingWalletTabWrapper', () => {
    return <LuckyKingWalletTab />;
});

const LuckyKingWalletDepositTabWrapper = observer('LuckyKingWalletDepositTabWrapper', () => {
    return <LuckyKingWalletTab />;
});

export interface TabDataPropsType {
    parent?: string;
    showHeader?: boolean;
    header?: {
        title: string | React.ReactNode | null;
        component?: React.ElementType<{}>;
        back?: boolean;
        noButton?: boolean;
        close?: boolean;
        icon?: () => JSX.Element;
        isSmallerVersion?: boolean;
    };
    component: () => React.ReactElement | null;
    isAvailable: boolean;
    stickyToRight?: boolean;
}

export class AccountTabsBuilderState {
    public constructor(
        private readonly config: ConfigComponents,
        private readonly language: LanguagesState,
        private readonly appCasinoState: AppCasinoState,
        private readonly usersState: UsersState,
        private readonly common: Common
    ) {
        makeObservable(this);
    }

    public getTabData = (tab: RightHandSideViewType | null): TabDataPropsType | null => {
        const { getTranslation, translateTokens } = this.language;
        const { limitsAndResponsibleGambling, rollingNetDepositLimit, accountHelperMail } = this.config.config;

        if (this.config.config.cryptoOperatorAccountType) {
            switch (tab?.account) {
                case 'login':
                    return {
                        component: (): React.ReactElement => <LuckyKingLogin isLoginRoute={true} />,
                        isAvailable: true,
                    };
                case 'forgot-password':
                    return {
                        parent: 'login',
                        header: {
                            title: getTranslation('account.tabs.forgot-password.title', 'Forgot password'),
                            back: true,
                        },
                        component: (): React.ReactElement => <ForgotPasswordTab />,
                        isAvailable: !this.isAuthorized,
                    };
                case 'reset-password':
                    return {
                        component: (): React.ReactElement => <LuckyKingResetPasswordContainer currentRoute={tab} />,
                        isAvailable: true,
                    };
                case 'signup':
                    return {
                        component: (): React.ReactElement => <SignUp />,
                        isAvailable: true,
                    };
                case 'wallet':
                    return {
                        component: this.isAuthorized
                            ? (): React.ReactElement => <LuckyKingWalletTabWrapper />
                            : (): React.ReactElement => <LuckyKingLogin />,
                        isAvailable: true,
                    };
                case 'add-credit':
                    return {
                        component: this.isAuthorized
                            ? (): React.ReactElement => <LuckyKingWalletDepositTabWrapper />
                            : (): React.ReactElement => <LuckyKingLogin />,
                        isAvailable: true,
                    };
                case 'game-info':
                    return {
                        component: (): React.ReactElement => <SidebarGameInfo appCasinoState={this.appCasinoState} />,
                        isAvailable: true,
                    };
                case 'static-resource-headline-promo-terms':
                    return {
                        component: (): React.ReactElement | null => (
                            <StaticResourceHeadlineCarouselTermsWrapper promoId={tab.promoId} />
                        ),
                        isAvailable: true,
                    };
                case 'terms-and-conditions-casino-banner':
                    return {
                        component: (): React.ReactElement => (
                            <CasinoBannersSidebar appCasinoState={this.appCasinoState} />
                        ),
                        isAvailable: true,
                    };
                case 'betting':
                    return {
                        component: (): React.ReactElement => <BettingTab />,
                        isAvailable: this.isMobile,
                    };
            }

            switch (tab?.account) {
                case 'summary':
                    return {
                        header: {
                            title: getTranslation('account.tabs.summary.title', 'Account'),
                        },
                        component: this.isAuthorized
                            ? (): React.ReactElement => <SummaryTabSecondary />
                            : (): React.ReactElement => <LuckyKingLogin />,
                        isAvailable: true,
                    };
                case 'personal-details':
                    return {
                        parent: 'summary',
                        header: {
                            title: getTranslation('account.tabs.personal-details.title', 'Personal details'),
                            back: true,
                        },
                        component: this.isAuthorized
                            ? (): React.ReactElement => <PersonalDetailsTab />
                            : (): React.ReactElement => <LuckyKingLogin />,
                        isAvailable: true,
                    };
                case 'preferences':
                    return {
                        parent: 'summary',
                        header: {
                            title: getTranslation('account.tabs.preferences.title', 'Settings & Contact Preferences'),
                            back: true,
                            isSmallerVersion: true,
                        },
                        component: this.isAuthorized
                            ? (): React.ReactElement => <PreferencesTab />
                            : (): React.ReactElement => <LuckyKingLogin />,
                        isAvailable: true,
                    };
                case 'customer-funds-protection':
                    return {
                        parent: 'summary',
                        component: this.isAuthorized
                            ? (): React.ReactElement => <CustomerFundsProtection />
                            : (): React.ReactElement => <LuckyKingLogin />,
                        isAvailable: this.customerFundsProtection,
                    };
                case 'static':
                    return {
                        parent: 'summary',
                        component: (): React.ReactElement => <StaticPageTab id={tab.static} />,
                        isAvailable: true,
                    };
                case 'terms-and-conditions-promos':
                    return {
                        header: {
                            title: getTranslation(
                                'account.tabs.terms-and-conditions-promos.title',
                                'Terms & Conditions'
                            ),
                            back: false,
                            noButton: true,
                        },
                        component: (): React.ReactElement => (
                            <PromotionTermsAndConditions
                                notificationId={tab.notificationId}
                                dataTest='promo-notification-tc-content'
                            />
                        ),
                        isAvailable: true,
                    };
                default:
                    return null;
            }
        }

        switch (tab?.account) {
            case 'login':
                return {
                    header: {
                        title: getTranslation('account.tabs.login.title', 'Login'),
                    },
                    component: (): React.ReactElement => <LoginTab isLoginRoute={true} />,
                    isAvailable: !this.isAuthorized,
                };
            case 'forgot-password':
                return {
                    parent: 'login',
                    header: {
                        title: getTranslation('account.tabs.forgot-password.title', 'Forgot password'),
                        back: true,
                    },
                    component: (): React.ReactElement => <ForgotPasswordTab />,
                    isAvailable: !this.isAuthorized,
                };
            case 'reset-password':
                return {
                    header: {
                        title: getTranslation('account.tabs.reset-password.title', 'Reset password'),
                    },
                    component: (): React.ReactElement => <ResetPasswordProcedure currentRoute={tab} />,
                    isAvailable: !this.isAuthorized,
                };
            case 'reset-password-privacy':
                return {
                    parent: 'reset-password',
                    header: {
                        title: getTranslation('account.tabs.reset-password-privacy.title', 'Privacy policy'),
                        back: true,
                        close: false,
                    },
                    component: (): React.ReactElement => <ResetPasswordPrivacy />,
                    isAvailable: !this.isAuthorized,
                };
            case 'reset-password-terms':
                return {
                    parent: 'reset-password',
                    header: {
                        title: getTranslation('account.tabs.reset-password-terms.title', 'Terms & conditions'),
                        back: true,
                        close: false,
                    },
                    component: (): React.ReactElement => <ResetPasswordTerms />,
                    isAvailable: !this.isAuthorized,
                };
            case 'reset-password-cookies':
                return {
                    parent: 'reset-password',
                    header: {
                        title: getTranslation('account.tabs.reset-password-cookies.title', 'Cookie policy'),
                        back: true,
                        close: false,
                    },
                    component: (): React.ReactElement => <ResetPasswordCookies />,
                    isAvailable: !this.isAuthorized,
                };
            // case 'verify-account-terms':
            //     return {
            //         header: {
            //             title: getTranslation('account.tabs.verify-account-terms.title', 'Verify Account')
            //         },
            //         component: (): React.ReactElement => <ResetPasswordProcedure />,
            //         isAvailable: !this.isAuthorized
            //     };
            case 'verify-account':
                return {
                    header: {
                        title: getTranslation('account.tabs.verify-account.title', 'Verify Account'),
                        back: false,
                        noButton: true,
                        close: true,
                    },
                    component: (): React.ReactElement => <VerifyAccountTab />,
                    isAvailable: !this.isAuthorized,
                };
            case 'summary':
                return {
                    header: {
                        title: getTranslation('account.tabs.summary.title', 'Account'),
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => <SummaryTab />
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: true,
                };
            case 'top-up': {
                const router = StarRouter.get(this.common);
                const handleViewType = (viewType: TopUpDrawerType | null): React.ReactElement => {
                    switch (viewType) {
                        case 'success': {
                            return (
                                <DepositFinalView
                                    accountHelperMail={accountHelperMail}
                                    isSignup={false}
                                    transactionId='yaspaOpenBanking'
                                    transactionCallback={(): Promise<null> => Promise.resolve(null)}
                                    hideDepositSuccess={(): void => router.redirectToTopUp(null)}
                                />
                            );
                        }
                        case 'failure': {
                            return (
                                <DepositFinalView
                                    accountHelperMail={accountHelperMail}
                                    isSignup={false}
                                    transactionId={undefined}
                                    transactionCallback={(): Promise<null> => Promise.resolve(null)}
                                    hideDepositSuccess={(): void => router.redirectToTopUp(null)}
                                />
                            );
                        }
                        default: {
                            return <DepositProcedureProvider />;
                        }
                    }
                };

                return {
                    parent: 'summary',
                    header: {
                        title: getTranslation('account.tabs.top-up.title', 'Deposit'),
                        back: this.isRightPanelIsStickyToRight ? false : true,
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => handleViewType(tab.view)
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: this.hasTopUpEnabled,
                    stickyToRight: this.isRightPanelIsStickyToRight,
                };
            }
            case 'credits':
                return {
                    parent: 'summary',
                    header: {
                        title: getTranslation('account.tabs.credits.title', 'Credits'),
                        back: this.isRightPanelIsStickyToRight ? false : true,
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => <Credits type={tab.type} />
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: this.config.config.bonuseEngine,
                    stickyToRight: this.isRightPanelIsStickyToRight,
                };
            case 'signup':
                return {
                    header: undefined,
                    component: this.config.config.accountDrawerNew
                        ? (): React.ReactElement => <SignUp />
                        : (): null => null,
                    isAvailable: !this.isAuthorized,
                };
            case 'finish-kyc':
                return {
                    parent: 'summary',
                    showHeader: false,
                    component: (): null => null,
                    isAvailable: true,
                };
            case 'withdraw': {
                const handleViewType = (viewType: TopUpDrawerType | null): React.ReactElement => {
                    switch (viewType) {
                        case 'success': {
                            return <WithdrawSuccess />;
                        }
                        case 'failure': {
                            return <WithdrawIssue failureType='serverIssue' />;
                        }
                        default: {
                            return <WithdrawTab />;
                        }
                    }
                };

                return {
                    parent: 'summary',
                    header: {
                        title: getTranslation('account.tabs.withdraw.title', 'Withdraw'),
                        back: true,
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => handleViewType(tab.view)
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: true,
                };
            }
            case 'bet-history':
                return {
                    parent: 'summary',
                    header: {
                        title: getTranslation('account.tabs.bet-history.title', 'Bet history'),
                        back: true,
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => <BetHistoryTab />
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: true,
                };
            case 'transaction-history':
                return {
                    parent: 'summary',
                    header: {
                        title: getTranslation('account.tabs.transaction-history.title', 'Transaction history'),
                        back: true,
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => <TransactionHistoryTab />
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: true,
                };
            case 'personal-details':
                return {
                    parent: 'summary',
                    header: {
                        title: getTranslation('account.tabs.personal-details.title', 'Personal details'),
                        back: true,
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => <PersonalDetailsTab />
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: true,
                };
            case 'preferences':
                return {
                    parent: 'summary',
                    header: {
                        title: getTranslation('account.tabs.preferences.title', 'Settings & Contact Preferences'),
                        back: true,
                        isSmallerVersion: true,
                    },
                    component: this.isAuthorized
                        ? (): React.ReactElement => <PreferencesTab />
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: true,
                };
            case 'limits':
                if (limitsAndResponsibleGambling === true) {
                    return {
                        parent: 'summary',
                        header: {
                            title:
                                this.config.config.depositLimitsType === 'calendarType'
                                    ? getTranslation('account.tabs.limits-safer.title', 'Limits & Safer Gambling')
                                    : translateTokens(
                                          getTranslation(
                                              'account.tabs.limits.title',
                                              'Limits & Responsible[separator]Gambling'
                                          ),
                                          (singleParam: LanguageTokenType) => {
                                              if (singleParam.tag === 'separator') {
                                                  return <br />;
                                              }
                                          }
                                      ),
                            back: true,
                        },
                        component: this.isAuthorized
                            ? (): React.ReactElement => <LimitsTab rollingNetDepositLimit={rollingNetDepositLimit} />
                            : (): React.ReactElement => <LoginTab />,
                        isAvailable: true,
                    };
                } else {
                    return null;
                }
            case 'customer-funds-protection':
                return {
                    parent: 'summary',
                    component: this.isAuthorized
                        ? (): React.ReactElement => <CustomerFundsProtection />
                        : (): React.ReactElement => <LoginTab />,
                    isAvailable: this.customerFundsProtection,
                };
            case 'static':
                return {
                    parent: 'signup',
                    component: (): React.ReactElement => <StaticPageTab id={tab.static} />,
                    isAvailable: true,
                };
            case 'static-resource-hero-widget':
                return {
                    parent: 'home',
                    component: (): React.ReactElement => <StaticResourceHeroWidget pageSlug={tab.static} />,
                    isAvailable: true,
                };
            case 'static-resource-carousel-promo-terms':
                return {
                    parent: 'home',
                    component: (): React.ReactElement => <StaticResourceHomePageCarouselTerms promoId={tab.promoId} />,
                    isAvailable: true,
                };
            case 'static-resource-headline-promo-terms':
                return {
                    parent: 'home',
                    component: (): React.ReactElement | null =>
                        tab.promoId === null ? null : <StaticResourceHeadlineCarouselTerms promoId={tab.promoId} />,
                    isAvailable: true,
                };
            case 'trader-chat':
                return {
                    header: {
                        title: <TraderChatTitleHeader />,
                        back: false,
                        noButton: true,
                        icon: (): JSX.Element => <TraderChatIcon />,
                    },
                    component:
                        this.isTraderChatOn && this.isAuthorized
                            ? (): React.ReactElement => <TraderChat />
                            : (): React.ReactElement => <LoginTab />,
                    isAvailable: !this.isAuthorized || this.isTraderChatOn,
                };
            case 'terms-and-conditions-promos':
                return {
                    header: {
                        title: getTranslation('account.tabs.terms-and-conditions-promos.title', 'Terms & Conditions'),
                        back: false,
                        noButton: true,
                    },
                    component: (): React.ReactElement => (
                        <PromotionTermsAndConditions
                            notificationId={tab.notificationId}
                            dataTest='promo-notification-tc-content'
                        />
                    ),
                    isAvailable: true,
                };
            case 'terms-and-conditions-promos-special':
                return {
                    header: {
                        title: getTranslation(
                            'account.tabs.terms-and-conditions-promos-special.title',
                            'Terms & Conditions'
                        ),
                        component: PromotionTermsAndConditionsHeader,
                        back: false,
                        noButton: true,
                    },
                    component: (): React.ReactElement => <PromotionTermsAndConditionsSpecial />,
                    isAvailable: true,
                };
            case 'betting':
                return {
                    component: (): React.ReactElement => <BettingTab />,
                    isAvailable: this.isMobile,
                };
            case 'game-info':
                return {
                    component: (): React.ReactElement => <SidebarGameInfo appCasinoState={this.appCasinoState} />,
                    isAvailable: this.isCasinoGameSelected,
                };
            case 'terms-and-conditions-casino-banner':
                return {
                    component: (): React.ReactElement => <CasinoBannersSidebar appCasinoState={this.appCasinoState} />,
                    isAvailable: true,
                };
            default:
                return null;
        }
    };

    @computed private get isAuthorized(): boolean {
        return this.common.session.isAuthorized;
    }

    @computed private get customerFundsProtection(): boolean {
        return this.config.config.customerFundsProtection;
    }

    @computed private get hasTopUpEnabled(): boolean {
        return this.config.config.hasTopUpEnabled;
    }

    @computed private get isTraderChatOn(): boolean {
        const basicData = this.usersState.basicData.get();
        const isTraderChatOn = (basicData.type === 'ready' && basicData.value?.chatEnabled) ?? false;

        return isTraderChatOn;
    }

    @computed private get isMobile(): boolean {
        const widthInner = getWindowInnerWidth();
        return widthInner !== null && widthInner < 1024;
    }

    @computed private get isRightPanelIsStickyToRight(): boolean {
        return this.appCasinoState.popupState.rightPanelAttachedToRightEdge;
    }

    @computed private get isCasinoGameSelected(): boolean {
        return this.appCasinoState.casinoSidebarGameInfoState.isGameSelected();
    }
}
