import { observer } from 'src/utils/mobx-react';
import React, { useEffect } from 'react';
import { YaspaIFrameStyled } from './Yaspa.style';
import { useCommon } from 'src/domains/common/Common';
import { z } from 'zod';

interface YaspaIframePropsType {
    src: string;
}

const iframeMessageZod = z.object({
    type: z.union([z.literal('success'), z.literal('failure'), z.literal('redirect')]),
    redirectUrl: z.string(),
});

const handleOnMessage = (message: string): void => {
    console.log(message, 'message');
    const validate = iframeMessageZod.safeParse(JSON.parse(message));
    if (validate.success === false) {
        return;
    }

    if (validate.data.type === 'redirect') {
        window.open(validate.data.redirectUrl, '_self');
    }
};

export interface MessageIframeYaspaDataType {
    eventType: 'onCloseHiddenIframeSuccess' | 'onCloseHiddenIframeFail';
}

interface YaspaIframePropsType {
    src: string;
    onMessage: (data: MessageIframeYaspaDataType) => void;
    className?: string;
    title?: string;
    dataTest?: string;
}

export const YaspaIframe = observer(
    'YaspaDeposit',
    ({ src, onMessage, className, title, dataTest }: YaspaIframePropsType): JSX.Element => {
        const common = useCommon();
        const onMessageHandler = (event: MessageEvent): void => {
            const { gatewayApiHost } = common.envVariables;
            if (gatewayApiHost === null) {
                return;
            }

            try {
                if (event.data.eventType !== undefined) onMessage(event.data);
            } catch (error) {
                console.error('Failed to parse event data:', error);
            }
        };

        useEffect(() => {
            window.addEventListener('message', onMessageHandler);

            return () => {
                window.removeEventListener('message', onMessageHandler);
            };
        }, []);

        return (
            <YaspaIFrameStyled
                className={className}
                src={src}
                onMessage={handleOnMessage}
                title={title}
                data-test={dataTest}
            />
        );
    }
);
