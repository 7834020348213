import { observer } from 'src/utils/mobx-react';
import { DepositYaspaPageType, ViewType, YaspaState } from './Yaspa.state';
import React, { useState } from 'react';
import { YaspaContainer, YaspaDepositButton, Separator, PoweredByYaspaLogo } from './Yaspa.style';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { useCommon } from 'src/domains/common/Common';
import PoweredByYaspa from 'src/domains/players/webview/components/WithdrawAndDeposit/assets/PoweredByYaspa.png';
import PoweredByYaspaSignup from 'src/domains/players/webview/components/WithdrawAndDeposit/assets/PoweredByYaspaSignup.png';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { useAppStateContext } from 'src/appState/AppState';

interface YaspaSegmentPropsType {
    depositYaspaPage: DepositYaspaPageType;
    viewType: ViewType;
    isSignup?: boolean;
    showSeparator?: boolean;
}

export const YaspaSegment = observer(
    'YaspaSegment',
    ({ depositYaspaPage, viewType, isSignup = false, showSeparator = true }: YaspaSegmentPropsType): JSX.Element => {
        const common = useCommon();
        const { appPlayersState } = useAppStateContext();
        const { usersState } = appPlayersState;
        const [state] = useState(() => new YaspaState(viewType, common, depositYaspaPage, usersState));

        return (
            <YaspaContainer>
                {showSeparator ? (
                    <Separator isSignup={isSignup}>
                        <span>Or</span>
                    </Separator>
                ) : null}
                <YaspaDepositButton
                    size='large'
                    disabled={state.isButtonDisabled}
                    onClick={state.submitDepositForm}
                >
                    {viewType === 'withdraw' ? (
                        <I18n
                            langKey='yaspa.button.start.journey.withdrawal'
                            defaultText='Instant Bank Withdrawal'
                        />
                    ) : (
                        <I18n
                            langKey='yaspa.button.start.journey.deposit'
                            defaultText='Instant Bank Deposit'
                        />
                    )}
                </YaspaDepositButton>
                {state.error === null ? null : (
                    <Messages
                        type='error'
                        marginTop='8px'
                        key={state.error}
                        message={state.error}
                    />
                )}
                <a
                    href='https://www.yaspa.com/'
                    rel='noreferrer noopener'
                    target='_blank'
                >
                    <PoweredByYaspaLogo
                        isSignup={isSignup}
                        src={isSignup ? PoweredByYaspaSignup : PoweredByYaspa}
                        alt='Powered by yaspa'
                    />
                </a>
            </YaspaContainer>
        );
    }
);
