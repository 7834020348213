import React from 'react';
import { observer } from 'src/utils/mobx-react';
import { useAppStateContext } from 'src/appState/AppState';
import { I18n } from 'src/domains/layouts/webview/components/language/I18n';
import { KycWarning } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/kycWarning/KycWarning';
import { PaymentAdditions } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/PaymentsAdditions';
import {
    CardInfo,
    CashBalance,
    CashBalanceWrapper,
    DepositTabWrapper,
} from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/DepositProcedure.style';
import { Input } from 'src/domains/players/webview/components/form/input/Input';
import { PayButton } from './PaysafeTab.style';
import { PaysafeTabState } from './PaysafeTab.state';
import { Messages } from 'src/domains/layouts/webview/components/Messages/Messages';
import { LanguagesState } from 'src/domains/layouts/state/languagesState/LanguagesState';
import { useCommon } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { RemainingLimit } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/remainingLimit/RemainingLimit';
import { DepositHeader } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/depositSignupPage/Deposit.style';
import { RingFencedFundsMessage } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/ringFencedFundsWrapper/RingFencedFundsWrapper';
import { YaspaSegment } from 'src/domains/players/webview/components/WithdrawAndDeposit/yaspa/YaspaSegment';

interface PropsType {
    state: PaysafeTabState;
    isSignup?: boolean;
}

export const PaysafeTab = observer('PaysafeTab', ({ state, isSignup = false }: PropsType): JSX.Element => {
    const common = useCommon();
    const {
        config: { showKycStatus, rollingNetDepositLimit, useOpenBanking },
        appPlayersState: { usersState },
    } = useAppStateContext();
    const languagesState = LanguagesState.get(common);

    return (
        <DepositTabWrapper isSignup={isSignup}>
            {isSignup ? (
                <DepositHeader data-test='deposit-header'>
                    <I18n
                        langKey='signup-journey.deposit.title'
                        defaultText='Deposit'
                    />
                </DepositHeader>
            ) : (
                <CashBalanceWrapper data-test='cash-balance'>
                    <I18n
                        langKey='account.top-up.cash-balance-label'
                        defaultText='Cash balance'
                    />
                    <CashBalance>{state.showBalance}</CashBalance>
                </CashBalanceWrapper>
            )}

            <Input
                type='text'
                maxLength={10}
                placeholder='0.00'
                currency={usersState.moneySymbol}
                label={
                    <I18n
                        langKey='account.top-up.description-label'
                        defaultText='Deposit amount'
                    />
                }
                state={state.depositAmount}
                onChange={state.onChange}
                onBlur={state.setAmount}
                colorTheme={isSignup ? 'dark' : 'light'}
                inputHasBackground={false}
                dataTest='deposit-amount-input'
            />

            <PaymentAdditions
                key='payment-additions'
                currency={usersState.moneySymbol}
                value={state.depositAmount.value}
                onChange={state.handleAdditionsChange}
            />

            {rollingNetDepositLimit === false || state.remainingLimitState.getRemainingLimit === undefined ? null : (
                <RemainingLimit
                    languagesState={languagesState}
                    value={state.remainingLimitState.getRemainingLimit.format(BasicDataModel.get(common).currency)}
                />
            )}

            {state.errorMessage.length > 0
                ? state.errorMessage.map((errorString) => (
                      <Messages
                          type='error'
                          marginTop='8px'
                          key={errorString}
                          message={errorString}
                      />
                  ))
                : null}

            {state.hasRingFencedFunds === false ? (
                <RingFencedFundsMessage
                    isMoreShown={state.showMoreRingFenceFlag}
                    toggleShowMore={state.toggleShowMore}
                />
            ) : null}

            <PayButton
                size='large'
                disabled={state.isButtonDisabled}
                onClick={state.submitDepositForm}
                type='submit'
                dataTest='deposit-pay-debit-card'
            >
                <I18n
                    langKey='account.top-up.pay-card-button'
                    defaultText='Pay using debit card'
                />
            </PayButton>

            <CardInfo data-test='deposit-card-info'>
                <I18n
                    langKey='account.top-up.top-upcard-info'
                    defaultText='Credit cards are no longer accepted'
                />
            </CardInfo>
            {useOpenBanking?.type === 'yaspa' ? (
                <YaspaSegment
                    viewType='deposit'
                    depositYaspaPage={state.depositYaspaPage}
                    isSignup={isSignup}
                />
            ) : null}

            {showKycStatus === true ? <KycWarning isSignup={false} /> : null}
        </DepositTabWrapper>
    );
});
