import React, { useState } from 'react';
import { observer } from 'src/utils/mobx-react';
import { DepositSuccess } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFinalView/depositSuccess/DepositSuccess';
import { DepositFailure } from 'src/domains/players/webview/components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/depositFailure/DepositFailure';
import { observable, makeObservable, computed } from 'mobx';
import {
    DarkLoadingSpinner,
    LoadingSpinner,
} from 'src/domains/layouts/webview/components/loaders/loadingSpinner/LoadingSpinner';
import { useAppStateContext } from 'src/appState/AppState';
import { timeout } from 'src_common/common/mobx-utils/timeout';
import { UsersState } from 'src/domains/players/state/UsersState';
import { Amount } from 'src_common/common/amount/Amount';

interface DepositFinalViewType {
    accountHelperMail: string;
    isSignup: boolean;
    transactionId: number | undefined | 'yaspaOpenBanking';
    transactionCallback: (transactionId: number) => Promise<FinalStepType | null>;
    hideDepositSuccess: () => void;
}
export interface TransactionCallbackResponseType {
    amount?: number;
    authCode?: string;
    createdAt: string;
    currency: string;
    orderId?: string;
    paymentMethod?: string;
    status: string;
}

interface TransactionType {
    data: TransactionCallbackResponseType;
    transactionId: string | number | undefined;
}

export type TransactionCallbackType = TransactionType;

export type FinalStepType =
    | {
          type: 'loading-view';
      }
    | {
          type: 'failure-view';
          failType: 'serverIssue' | 'failWithReceipt';
          data?: TransactionCallbackType;
      }
    | {
          type: 'success-view';
      };

class FinalViewState {
    @observable public step: FinalStepType = { type: 'loading-view' };

    public constructor(
        private readonly usersState: UsersState,
        private readonly transactionId: number | undefined | 'yaspaOpenBanking',
        private readonly transactionCallback: (transactionId: number) => Promise<FinalStepType | null>
    ) {
        makeObservable(this);

        (async (): Promise<void> => {
            for (let i = 0; i < 15; i++) {
                if (this.transactionId === undefined) {
                    this.step = {
                        type: 'failure-view',
                        failType: 'serverIssue',
                    };
                    return;
                }

                if (this.transactionId === 'yaspaOpenBanking') {
                    this.step = { type: 'success-view' };
                    return;
                }
                const response = await this.transactionCallback(this.transactionId);

                if (response === null) {
                    await timeout(4000);
                    continue;
                }

                this.step = response;
                return;
            }

            if (this.step.type === 'loading-view') {
                this.step = { type: 'failure-view', failType: 'serverIssue' };
                return;
            }
        })().catch(() => {
            this.step = { type: 'failure-view', failType: 'serverIssue' };
        });
    }

    @computed public get showBalance(): string | undefined {
        const playableBalance = this.usersState.walletData.valueReady?.playableBalance;

        if (playableBalance !== undefined) {
            return this.usersState.money(new Amount(playableBalance));
        }
    }
}

export const DepositFinalView = observer(
    'DepositFinalView',
    ({ accountHelperMail, isSignup, transactionId, transactionCallback, hideDepositSuccess }: DepositFinalViewType) => {
        const {
            appPlayersState: { usersState },
        } = useAppStateContext();
        const [stateLocal] = useState(() => new FinalViewState(usersState, transactionId, transactionCallback));

        const renderContent = (): JSX.Element => {
            switch (stateLocal.step.type) {
                case 'loading-view':
                    return isSignup === true ? <LoadingSpinner /> : <DarkLoadingSpinner />;
                case 'failure-view':
                    return (
                        <DepositFailure
                            isSignup={isSignup}
                            issueType={stateLocal.step.failType}
                            accountHelperMail={accountHelperMail}
                            transactionCallback={stateLocal.step.data}
                            hideDepositSuccess={hideDepositSuccess}
                        />
                    );
                case 'success-view':
                    return (
                        <DepositSuccess
                            isSignup={isSignup}
                            balance={stateLocal.showBalance}
                            hideDepositSuccess={hideDepositSuccess}
                        />
                    );
            }
        };

        return renderContent();
    }
);
