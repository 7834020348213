import { action, computed, makeObservable, observable } from 'mobx';
import { GoogleTagManagerState } from 'src/domains/layouts/state/googleState/GoogleTagManagerState';
import { Amount } from 'src_common/common/amount/Amount';
import { Common } from 'src/domains/common/Common';
import { BasicDataModel } from 'src/domains/players/state/BasicDataModel';
import { FormInputState } from 'src_common/common/mobx-utils/Form2/FormInputState';
import { generateRandomAlphanumeric } from 'src/domains/players/webview//components/WithdrawAndDeposit/depositProcedure/topUpProcedureParts/helpers/generateRandomAlphanumeric';
import { ConfigComponents } from 'src/domains/layouts/config/features/config';
import { apiDepositInitiateRequest } from 'src/api_openapi/v2/yaspa/apiDepositInitiate';
import {
    YaspaBankingApiYaspaPayInRequestType,
    YaspaBankingApiPayOutRequestType,
} from 'src/api_openapi/v2/yaspa/schemas';
import { apiWithdrawInitiateRequest } from 'src/api_openapi/v2/yaspa/apiWithdrawInitiate';
import { UsersState } from 'src/domains/players/state/UsersState';

export type ViewType = 'withdraw' | 'deposit';

export interface DepositCustomerDataType {
    requestType: 'deposit';
    body: YaspaBankingApiYaspaPayInRequestType;
}

export interface WithdrawCustomerDataType {
    requestType: 'withdraw';
    body: YaspaBankingApiPayOutRequestType;
}

export type CustomerDataType = DepositCustomerDataType | WithdrawCustomerDataType;
export interface DepositYaspaPageType {
    isSignup: boolean;
    getAmountInput: () => FormInputState<string, Amount>;
    handleRedirectToYaspaFrame: (url: string) => void;
}

export class YaspaState {
    @observable private isSubmitting: boolean = false;
    @observable public error: string | null = null;

    public constructor(
        private readonly viewType: ViewType,
        private readonly common: Common,
        private depositYaspaPage: DepositYaspaPageType,
        private usersState: UsersState
    ) {
        makeObservable(this);
    }

    @computed public get customerData(): CustomerDataType | null {
        const customer = BasicDataModel.get(this.common).basicDataReady;
        if (customer === null) {
            return null;
        }

        const amountInput = this.depositYaspaPage.getAmountInput();
        if (amountInput.result.value.type === 'error') {
            return null;
        }

        const { gatewayApiHost } = this.common.envVariables;
        if (gatewayApiHost === null) {
            this.createErrorMessage('Unknown gatewayApiHost');
            return null;
        }

        const randomReferenceNumber = generateRandomAlphanumeric(15);
        if (this.viewType === 'deposit') {
            return {
                requestType: 'deposit',
                body: {
                    customerIdentifier: customer.id.toString(),
                    paymentGiro: customer.currency === 'GBP' ? 'FPS' : 'SEPA',
                    amount: amountInput.result.value.data.toFixed(2),
                    reference: randomReferenceNumber,
                    journeyType: 'HOSTED_PAYMENT',
                    currency: customer.currency,
                    supportedCountries: ['GB'], // hardcoded on BE
                    successBankRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/deposit/callback/success`,
                    successRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/deposit/callback/success`,
                    failureBankRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/deposit/callback/failure`,
                    failureRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/deposit/callback/failure`,
                    searchableText: `${this.common.envVariables.universe}-${customer.id}`,
                },
            };
        } else {
            return {
                requestType: 'withdraw',
                body: {
                    customerIdentifier: customer.id.toString(),
                    paymentGiro: customer.currency === 'GBP' ? 'FPS' : 'SEPA',
                    amount: amountInput.result.value.data.toFixed(2),
                    reference: randomReferenceNumber,
                    currency: customer.currency,
                    description: 'withdraw',
                    supportedCountries: ['GB'],
                    language: 'EN',
                    successBankRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/withdrawal/callback/success`,
                    successRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/withdrawal/callback/success`,
                    failureBankRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/withdrawal/callback/failure`,
                    failureRedirectUrl: `${gatewayApiHost}/yaspa-banking-api/v1/withdrawal/callback/failure`,
                    channel: 'default',
                },
            };
        }
    }

    @computed public get isButtonDisabled(): boolean {
        if (this.customerData === null) {
            return true;
        }

        if (this.isSubmitting) {
            return true;
        }

        return this.checkRequestedAmountForWithdraw;
    }

    @computed private get checkRequestedAmountForWithdraw(): boolean {
        if (this.viewType === 'withdraw') {
            const amountInput = this.depositYaspaPage.getAmountInput();
            if (amountInput.result.value.type === 'error') {
                return true;
            }

            const withdrawableBalance = this.usersState.walletData.valueReady?.withdrawableBalance;
            if (withdrawableBalance === undefined) {
                return true;
            }

            const withdrawableBalanceAmount = new Amount(withdrawableBalance);

            if (amountInput.result.value.data.isGreaterThan(withdrawableBalanceAmount)) {
                return true;
            }
        }
        return false;
    }

    @action public submitDepositForm = async (): Promise<void> => {
        if (this.customerData === null) {
            this.createErrorMessage('Unknown customer data');
            return;
        }

        try {
            this.isSubmitting = true;
            this.error = null;

            const oldFormatAmount = ConfigComponents.get(this.common).precision.valueOldFormat(
                new Amount(this.customerData.body.amount)
            );

            this.trackDeposit(oldFormatAmount, this.depositYaspaPage.isSignup);

            const urlResponse = await this.getUrl(this.customerData);

            if (urlResponse === null) return;

            this.depositYaspaPage.handleRedirectToYaspaFrame(urlResponse);
        } catch (e) {
            this.createErrorMessage();
        } finally {
            this.isSubmitting = false;
        }
    };

    private trackDeposit(oldFormatAmount: number, isSignup: boolean): void {
        const googleTagManager = GoogleTagManagerState.get(this.common);
        if (isSignup) {
            googleTagManager.depositedMoney(oldFormatAmount, true);
            googleTagManager.gtmSignUpStepFour(oldFormatAmount);
        } else {
            googleTagManager.depositedMoney(oldFormatAmount, false);
        }
    }

    private getUrl = async (customerData: CustomerDataType): Promise<string | null> => {
        const { gatewayApiHost } = this.common.envVariables;
        const customerIdentifier = customerData.body.customerIdentifier;

        if (gatewayApiHost === null) {
            this.createErrorMessage('Unknown gatewayApiHost');
            return null;
        }
        const requestId = `${this.common.envVariables.universe}-${customerIdentifier}`;
        if (customerData.requestType === 'deposit') {
            const response = await apiDepositInitiateRequest(
                gatewayApiHost,
                { requestBody: customerData.body },
                { 'x-client-request-id': requestId }
            );

            if (response.status !== 200) {
                this.createErrorMessage(response.body.errors?.[0]?.message);
                return null;
            }
            return response.body.data.link;
        } else {
            const response = await apiWithdrawInitiateRequest(
                gatewayApiHost,
                { requestBody: customerData.body },
                { 'x-client-request-id': requestId }
            );

            if (response.status !== 200) {
                this.createErrorMessage(response.body.errors?.[0]?.message);
                return null;
            }
            return response.body.data.link;
        }
    };

    @action public createErrorMessage = (message?: string | undefined): void => {
        this.error = message ?? 'Unknown Error';
    };
}
